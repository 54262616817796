export default class BTP {

    /**
     * @return {Promise}   Returns a promise with bluetooth Characteristic
     */
    static connect(){
        return new Promise((resolve, reject) => {
                let gattServer = null
                navigator.bluetooth.requestDevice({
                    filters: [{
                        services: ['000018f0-0000-1000-8000-00805f9b34fb']
                    }]
                })
                .then(device => {
                    gattServer = device.gatt
                    return device.gatt.connect();
                })
                .then(server => server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb"))
                .then(service => service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb"))
                .then(characteristic => {
                    return resolve({characteristic,gattServer});
                })
                .catch((err)=>{
                    return reject({
                        message: 'Não foi possível conectar.',
                        err: err
                    })
                });
        })
    }

    /**
     * @param  {BluetoothRemoteGattCharacteristic}      characteristic
     * @param  {ArrayBuffer}                            value
     * @return {Promise}
     */
    static Print(characteristic,value){
        return new Promise((resolve, reject)=>{
            characteristic.writeValueWithResponse(value)
                .then(()=>{
                    return resolve()
                }).catch((err)=>{
                return reject({
                    message: 'Não foi possível imprimir.',
                    err: err
                })
            })
        })
    }
    
    static RawbtPrint(canvas){
        document.location='rawbt:'+ canvas.toDataURL()
    }
}
